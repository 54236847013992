<template>
  <notification-read :key="this.$route.params.id" />
</template>

<script>

import { formatDate } from '@core/utils/filter'
import NotificationRead from './component/NotificationMessageRead.vue'

export default {
  components: {
    NotificationRead,
  },
  setup() {
    return {
      formatDate,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
}
</script>

<style>

</style>
